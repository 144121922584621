/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * Content management API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FreelancerPriceValue
 */
export interface FreelancerPriceValue {
    /**
     * 
     * @type {number}
     * @memberof FreelancerPriceValue
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof FreelancerPriceValue
     */
    currency: string;
    /**
     * 
     * @type {string}
     * @memberof FreelancerPriceValue
     */
    formatted: string;
}

/**
 * Check if a given object implements the FreelancerPriceValue interface.
 */
export function instanceOfFreelancerPriceValue(value: object): value is FreelancerPriceValue {
    if (!('amount' in value) || value['amount'] === undefined) return false;
    if (!('currency' in value) || value['currency'] === undefined) return false;
    if (!('formatted' in value) || value['formatted'] === undefined) return false;
    return true;
}

export function FreelancerPriceValueFromJSON(json: any): FreelancerPriceValue {
    return FreelancerPriceValueFromJSONTyped(json, false);
}

export function FreelancerPriceValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): FreelancerPriceValue {
    if (json == null) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'currency': json['currency'],
        'formatted': json['formatted'],
    };
}

export function FreelancerPriceValueToJSON(value?: FreelancerPriceValue | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'amount': value['amount'],
        'currency': value['currency'],
        'formatted': value['formatted'],
    };
}

