/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * Content management API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FreelancerLocation
 */
export interface FreelancerLocation {
    /**
     * 
     * @type {string}
     * @memberof FreelancerLocation
     */
    locationType: FreelancerLocationLocationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FreelancerLocation
     */
    city?: string;
}


/**
 * @export
 */
export const FreelancerLocationLocationTypeEnum = {
    Everywhere: 'EVERYWHERE',
    Exact: 'EXACT',
    Near: 'NEAR',
    Remote: 'REMOTE',
    SearchLocationFarFromHome: 'SEARCH_LOCATION_FAR_FROM_HOME'
} as const;
export type FreelancerLocationLocationTypeEnum = typeof FreelancerLocationLocationTypeEnum[keyof typeof FreelancerLocationLocationTypeEnum];


/**
 * Check if a given object implements the FreelancerLocation interface.
 */
export function instanceOfFreelancerLocation(value: object): value is FreelancerLocation {
    if (!('locationType' in value) || value['locationType'] === undefined) return false;
    return true;
}

export function FreelancerLocationFromJSON(json: any): FreelancerLocation {
    return FreelancerLocationFromJSONTyped(json, false);
}

export function FreelancerLocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): FreelancerLocation {
    if (json == null) {
        return json;
    }
    return {
        
        'locationType': json['locationType'],
        'city': json['city'] == null ? undefined : json['city'],
    };
}

export function FreelancerLocationToJSON(value?: FreelancerLocation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'locationType': value['locationType'],
        'city': value['city'],
    };
}

