/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * Content management API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FreelancerSkill
 */
export interface FreelancerSkill {
    /**
     * 
     * @type {string}
     * @memberof FreelancerSkill
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof FreelancerSkill
     */
    certified: boolean;
    /**
     * 
     * @type {string}
     * @memberof FreelancerSkill
     */
    level: FreelancerSkillLevelEnum;
}


/**
 * @export
 */
export const FreelancerSkillLevelEnum = {
    None: 'NONE',
    Beginner: 'BEGINNER',
    Intermediate: 'INTERMEDIATE',
    Advanced: 'ADVANCED'
} as const;
export type FreelancerSkillLevelEnum = typeof FreelancerSkillLevelEnum[keyof typeof FreelancerSkillLevelEnum];


/**
 * Check if a given object implements the FreelancerSkill interface.
 */
export function instanceOfFreelancerSkill(value: object): value is FreelancerSkill {
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('certified' in value) || value['certified'] === undefined) return false;
    if (!('level' in value) || value['level'] === undefined) return false;
    return true;
}

export function FreelancerSkillFromJSON(json: any): FreelancerSkill {
    return FreelancerSkillFromJSONTyped(json, false);
}

export function FreelancerSkillFromJSONTyped(json: any, ignoreDiscriminator: boolean): FreelancerSkill {
    if (json == null) {
        return json;
    }
    return {
        
        'label': json['label'],
        'certified': json['certified'],
        'level': json['level'],
    };
}

export function FreelancerSkillToJSON(value?: FreelancerSkill | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'label': value['label'],
        'certified': value['certified'],
        'level': value['level'],
    };
}

