/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * Content management API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FreelancerAvailability
 */
export interface FreelancerAvailability {
    /**
     * 
     * @type {string}
     * @memberof FreelancerAvailability
     */
    status: FreelancerAvailabilityStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof FreelancerAvailability
     */
    nextAvailabilityDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof FreelancerAvailability
     */
    workAvailability: FreelancerAvailabilityWorkAvailabilityEnum;
    /**
     * 
     * @type {string}
     * @memberof FreelancerAvailability
     */
    frequency?: FreelancerAvailabilityFrequencyEnum;
}


/**
 * @export
 */
export const FreelancerAvailabilityStatusEnum = {
    AvailableAndVerified: 'AVAILABLE_AND_VERIFIED',
    Available: 'AVAILABLE',
    AvailableSoon: 'AVAILABLE_SOON',
    NotAvailableWithDate: 'NOT_AVAILABLE_WITH_DATE',
    NotAvailable: 'NOT_AVAILABLE'
} as const;
export type FreelancerAvailabilityStatusEnum = typeof FreelancerAvailabilityStatusEnum[keyof typeof FreelancerAvailabilityStatusEnum];

/**
 * @export
 */
export const FreelancerAvailabilityWorkAvailabilityEnum = {
    Partial: 'PARTIAL',
    FullTime: 'FULL_TIME',
    NotAvailable: 'NOT_AVAILABLE'
} as const;
export type FreelancerAvailabilityWorkAvailabilityEnum = typeof FreelancerAvailabilityWorkAvailabilityEnum[keyof typeof FreelancerAvailabilityWorkAvailabilityEnum];

/**
 * @export
 */
export const FreelancerAvailabilityFrequencyEnum = {
    OneDayPerWeek: 'ONE_DAY_PER_WEEK',
    TwoDaysPerWeek: 'TWO_DAYS_PER_WEEK',
    ThreeDaysPerWeek: 'THREE_DAYS_PER_WEEK',
    FourDaysPerWeek: 'FOUR_DAYS_PER_WEEK',
    EveningsAndWeekends: 'EVENINGS_AND_WEEKENDS',
    FullTime: 'FULL_TIME'
} as const;
export type FreelancerAvailabilityFrequencyEnum = typeof FreelancerAvailabilityFrequencyEnum[keyof typeof FreelancerAvailabilityFrequencyEnum];


/**
 * Check if a given object implements the FreelancerAvailability interface.
 */
export function instanceOfFreelancerAvailability(value: object): value is FreelancerAvailability {
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('workAvailability' in value) || value['workAvailability'] === undefined) return false;
    return true;
}

export function FreelancerAvailabilityFromJSON(json: any): FreelancerAvailability {
    return FreelancerAvailabilityFromJSONTyped(json, false);
}

export function FreelancerAvailabilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): FreelancerAvailability {
    if (json == null) {
        return json;
    }
    return {
        
        'status': json['status'],
        'nextAvailabilityDate': json['nextAvailabilityDate'] == null ? undefined : (new Date(json['nextAvailabilityDate'])),
        'workAvailability': json['workAvailability'],
        'frequency': json['frequency'] == null ? undefined : json['frequency'],
    };
}

export function FreelancerAvailabilityToJSON(value?: FreelancerAvailability | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status': value['status'],
        'nextAvailabilityDate': value['nextAvailabilityDate'] == null ? undefined : ((value['nextAvailabilityDate']).toISOString().substring(0,10)),
        'workAvailability': value['workAvailability'],
        'frequency': value['frequency'],
    };
}

