/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * Content management API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
import type { FreelancerAvailability } from './';
import {
    FreelancerAvailabilityFromJSON,
    FreelancerAvailabilityFromJSONTyped,
    FreelancerAvailabilityToJSON,
} from './';
import type { FreelancerLocation } from './';
import {
    FreelancerLocationFromJSON,
    FreelancerLocationFromJSONTyped,
    FreelancerLocationToJSON,
} from './';
import type { FreelancerPhoto } from './';
import {
    FreelancerPhotoFromJSON,
    FreelancerPhotoFromJSONTyped,
    FreelancerPhotoToJSON,
} from './';
import type { FreelancerPortfolio } from './';
import {
    FreelancerPortfolioFromJSON,
    FreelancerPortfolioFromJSONTyped,
    FreelancerPortfolioToJSON,
} from './';
import type { FreelancerPrice } from './';
import {
    FreelancerPriceFromJSON,
    FreelancerPriceFromJSONTyped,
    FreelancerPriceToJSON,
} from './';
import type { FreelancerSkill } from './';
import {
    FreelancerSkillFromJSON,
    FreelancerSkillFromJSONTyped,
    FreelancerSkillToJSON,
} from './';
import type { FreelancerStatistics } from './';
import {
    FreelancerStatisticsFromJSON,
    FreelancerStatisticsFromJSONTyped,
    FreelancerStatisticsToJSON,
} from './';

/**
 * 
 * @export
 * @interface FreelancerProfile
 */
export interface FreelancerProfile {
    /**
     * 
     * @type {string}
     * @memberof FreelancerProfile
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FreelancerProfile
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof FreelancerProfile
     */
    lastNameNormalized: string;
    /**
     * 
     * @type {string}
     * @memberof FreelancerProfile
     */
    headline: string;
    /**
     * 
     * @type {FreelancerPhoto}
     * @memberof FreelancerProfile
     */
    photo: FreelancerPhoto;
    /**
     * 
     * @type {FreelancerLocation}
     * @memberof FreelancerProfile
     */
    location: FreelancerLocation;
    /**
     * 
     * @type {FreelancerPrice}
     * @memberof FreelancerProfile
     */
    price: FreelancerPrice;
    /**
     * 
     * @type {FreelancerAvailability}
     * @memberof FreelancerProfile
     */
    availability?: FreelancerAvailability;
    /**
     * 
     * @type {FreelancerStatistics}
     * @memberof FreelancerProfile
     */
    stats: FreelancerStatistics;
    /**
     * 
     * @type {Array<string>}
     * @memberof FreelancerProfile
     */
    badges: Array<FreelancerProfileBadgesEnum>;
    /**
     * 
     * @type {Array<FreelancerSkill>}
     * @memberof FreelancerProfile
     */
    skills: Array<FreelancerSkill>;
    /**
     * 
     * @type {Array<FreelancerPortfolio>}
     * @memberof FreelancerProfile
     */
    portfolio: Array<FreelancerPortfolio>;
    /**
     * 
     * @type {string}
     * @memberof FreelancerProfile
     */
    url: string;
}


/**
 * @export
 */
export const FreelancerProfileBadgesEnum = {
    NewMalter: 'NEW_MALTER',
    MaltLinker: 'MALT_LINKER',
    SuperMalter1: 'SUPER_MALTER_1',
    SuperMalter2: 'SUPER_MALTER_2',
    SuperMalter3: 'SUPER_MALTER_3'
} as const;
export type FreelancerProfileBadgesEnum = typeof FreelancerProfileBadgesEnum[keyof typeof FreelancerProfileBadgesEnum];


/**
 * Check if a given object implements the FreelancerProfile interface.
 */
export function instanceOfFreelancerProfile(value: object): value is FreelancerProfile {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastNameNormalized' in value) || value['lastNameNormalized'] === undefined) return false;
    if (!('headline' in value) || value['headline'] === undefined) return false;
    if (!('photo' in value) || value['photo'] === undefined) return false;
    if (!('location' in value) || value['location'] === undefined) return false;
    if (!('price' in value) || value['price'] === undefined) return false;
    if (!('stats' in value) || value['stats'] === undefined) return false;
    if (!('badges' in value) || value['badges'] === undefined) return false;
    if (!('skills' in value) || value['skills'] === undefined) return false;
    if (!('portfolio' in value) || value['portfolio'] === undefined) return false;
    if (!('url' in value) || value['url'] === undefined) return false;
    return true;
}

export function FreelancerProfileFromJSON(json: any): FreelancerProfile {
    return FreelancerProfileFromJSONTyped(json, false);
}

export function FreelancerProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): FreelancerProfile {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': json['firstName'],
        'lastNameNormalized': json['lastNameNormalized'],
        'headline': json['headline'],
        'photo': FreelancerPhotoFromJSON(json['photo']),
        'location': FreelancerLocationFromJSON(json['location']),
        'price': FreelancerPriceFromJSON(json['price']),
        'availability': json['availability'] == null ? undefined : FreelancerAvailabilityFromJSON(json['availability']),
        'stats': FreelancerStatisticsFromJSON(json['stats']),
        'badges': json['badges'],
        'skills': ((json['skills'] as Array<any>).map(FreelancerSkillFromJSON)),
        'portfolio': ((json['portfolio'] as Array<any>).map(FreelancerPortfolioFromJSON)),
        'url': json['url'],
    };
}

export function FreelancerProfileToJSON(value?: FreelancerProfile | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'firstName': value['firstName'],
        'lastNameNormalized': value['lastNameNormalized'],
        'headline': value['headline'],
        'photo': FreelancerPhotoToJSON(value['photo']),
        'location': FreelancerLocationToJSON(value['location']),
        'price': FreelancerPriceToJSON(value['price']),
        'availability': FreelancerAvailabilityToJSON(value['availability']),
        'stats': FreelancerStatisticsToJSON(value['stats']),
        'badges': value['badges'],
        'skills': ((value['skills'] as Array<any>).map((v) => FreelancerSkillToJSON(v))),
        'portfolio': ((value['portfolio'] as Array<any>).map((v) => FreelancerPortfolioToJSON(v))),
        'url': value['url'],
    };
}

