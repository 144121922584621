/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * Content management API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
import type { FreelancerPriceValue } from './';
import {
    FreelancerPriceValueFromJSON,
    FreelancerPriceValueFromJSONTyped,
    FreelancerPriceValueToJSON,
} from './';

/**
 * 
 * @export
 * @interface FreelancerPrice
 */
export interface FreelancerPrice {
    /**
     * 
     * @type {string}
     * @memberof FreelancerPrice
     */
    visibility: FreelancerPriceVisibilityEnum;
    /**
     * 
     * @type {FreelancerPriceValue}
     * @memberof FreelancerPrice
     */
    value?: FreelancerPriceValue;
}


/**
 * @export
 */
export const FreelancerPriceVisibilityEnum = {
    OnDemand: 'ON_DEMAND',
    Visible: 'VISIBLE'
} as const;
export type FreelancerPriceVisibilityEnum = typeof FreelancerPriceVisibilityEnum[keyof typeof FreelancerPriceVisibilityEnum];


/**
 * Check if a given object implements the FreelancerPrice interface.
 */
export function instanceOfFreelancerPrice(value: object): value is FreelancerPrice {
    if (!('visibility' in value) || value['visibility'] === undefined) return false;
    return true;
}

export function FreelancerPriceFromJSON(json: any): FreelancerPrice {
    return FreelancerPriceFromJSONTyped(json, false);
}

export function FreelancerPriceFromJSONTyped(json: any, ignoreDiscriminator: boolean): FreelancerPrice {
    if (json == null) {
        return json;
    }
    return {
        
        'visibility': json['visibility'],
        'value': json['value'] == null ? undefined : FreelancerPriceValueFromJSON(json['value']),
    };
}

export function FreelancerPriceToJSON(value?: FreelancerPrice | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'visibility': value['visibility'],
        'value': FreelancerPriceValueToJSON(value['value']),
    };
}

