/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * Content management API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
import type { PortfolioItemPicture } from './';
import {
    PortfolioItemPictureFromJSON,
    PortfolioItemPictureFromJSONTyped,
    PortfolioItemPictureToJSON,
} from './';

/**
 * 
 * @export
 * @interface FreelancerPortfolio
 */
export interface FreelancerPortfolio {
    /**
     * 
     * @type {string}
     * @memberof FreelancerPortfolio
     */
    title: string;
    /**
     * 
     * @type {number}
     * @memberof FreelancerPortfolio
     */
    index: number;
    /**
     * 
     * @type {string}
     * @memberof FreelancerPortfolio
     */
    type: FreelancerPortfolioTypeEnum;
    /**
     * 
     * @type {PortfolioItemPicture}
     * @memberof FreelancerPortfolio
     */
    picture: PortfolioItemPicture;
}


/**
 * @export
 */
export const FreelancerPortfolioTypeEnum = {
    Video: 'VIDEO',
    Photo: 'PHOTO'
} as const;
export type FreelancerPortfolioTypeEnum = typeof FreelancerPortfolioTypeEnum[keyof typeof FreelancerPortfolioTypeEnum];


/**
 * Check if a given object implements the FreelancerPortfolio interface.
 */
export function instanceOfFreelancerPortfolio(value: object): value is FreelancerPortfolio {
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('index' in value) || value['index'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('picture' in value) || value['picture'] === undefined) return false;
    return true;
}

export function FreelancerPortfolioFromJSON(json: any): FreelancerPortfolio {
    return FreelancerPortfolioFromJSONTyped(json, false);
}

export function FreelancerPortfolioFromJSONTyped(json: any, ignoreDiscriminator: boolean): FreelancerPortfolio {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'],
        'index': json['index'],
        'type': json['type'],
        'picture': PortfolioItemPictureFromJSON(json['picture']),
    };
}

export function FreelancerPortfolioToJSON(value?: FreelancerPortfolio | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'index': value['index'],
        'type': value['type'],
        'picture': PortfolioItemPictureToJSON(value['picture']),
    };
}

