/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * Content management API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PortfolioItemPicture
 */
export interface PortfolioItemPicture {
    /**
     * 
     * @type {string}
     * @memberof PortfolioItemPicture
     */
    lowResolutionUrl: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioItemPicture
     */
    mediumResolutionUrl: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioItemPicture
     */
    highResolutionUrl: string;
}

/**
 * Check if a given object implements the PortfolioItemPicture interface.
 */
export function instanceOfPortfolioItemPicture(value: object): value is PortfolioItemPicture {
    if (!('lowResolutionUrl' in value) || value['lowResolutionUrl'] === undefined) return false;
    if (!('mediumResolutionUrl' in value) || value['mediumResolutionUrl'] === undefined) return false;
    if (!('highResolutionUrl' in value) || value['highResolutionUrl'] === undefined) return false;
    return true;
}

export function PortfolioItemPictureFromJSON(json: any): PortfolioItemPicture {
    return PortfolioItemPictureFromJSONTyped(json, false);
}

export function PortfolioItemPictureFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortfolioItemPicture {
    if (json == null) {
        return json;
    }
    return {
        
        'lowResolutionUrl': json['lowResolutionUrl'],
        'mediumResolutionUrl': json['mediumResolutionUrl'],
        'highResolutionUrl': json['highResolutionUrl'],
    };
}

export function PortfolioItemPictureToJSON(value?: PortfolioItemPicture | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'lowResolutionUrl': value['lowResolutionUrl'],
        'mediumResolutionUrl': value['mediumResolutionUrl'],
        'highResolutionUrl': value['highResolutionUrl'],
    };
}

