/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * Content management API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FreelancerPhoto
 */
export interface FreelancerPhoto {
    /**
     * 
     * @type {string}
     * @memberof FreelancerPhoto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FreelancerPhoto
     */
    legend?: string;
    /**
     * 
     * @type {string}
     * @memberof FreelancerPhoto
     */
    baseUrl: string;
    /**
     * 
     * @type {string}
     * @memberof FreelancerPhoto
     */
    absoluteUrl: string;
}

/**
 * Check if a given object implements the FreelancerPhoto interface.
 */
export function instanceOfFreelancerPhoto(value: object): value is FreelancerPhoto {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('baseUrl' in value) || value['baseUrl'] === undefined) return false;
    if (!('absoluteUrl' in value) || value['absoluteUrl'] === undefined) return false;
    return true;
}

export function FreelancerPhotoFromJSON(json: any): FreelancerPhoto {
    return FreelancerPhotoFromJSONTyped(json, false);
}

export function FreelancerPhotoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FreelancerPhoto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'legend': json['legend'] == null ? undefined : json['legend'],
        'baseUrl': json['baseUrl'],
        'absoluteUrl': json['absoluteUrl'],
    };
}

export function FreelancerPhotoToJSON(value?: FreelancerPhoto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'legend': value['legend'],
        'baseUrl': value['baseUrl'],
        'absoluteUrl': value['absoluteUrl'],
    };
}

