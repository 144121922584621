/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * Content management API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FreelancerStatistics
 */
export interface FreelancerStatistics {
    /**
     * 
     * @type {number}
     * @memberof FreelancerStatistics
     */
    rating: number;
    /**
     * 
     * @type {number}
     * @memberof FreelancerStatistics
     */
    missionsCount: number;
    /**
     * 
     * @type {number}
     * @memberof FreelancerStatistics
     */
    recommendationsCount: number;
    /**
     * 
     * @type {number}
     * @memberof FreelancerStatistics
     */
    appraisalsWithRatesCount: number;
    /**
     * 
     * @type {boolean}
     * @memberof FreelancerStatistics
     */
    invalidatedCharter: boolean;
}

/**
 * Check if a given object implements the FreelancerStatistics interface.
 */
export function instanceOfFreelancerStatistics(value: object): value is FreelancerStatistics {
    if (!('rating' in value) || value['rating'] === undefined) return false;
    if (!('missionsCount' in value) || value['missionsCount'] === undefined) return false;
    if (!('recommendationsCount' in value) || value['recommendationsCount'] === undefined) return false;
    if (!('appraisalsWithRatesCount' in value) || value['appraisalsWithRatesCount'] === undefined) return false;
    if (!('invalidatedCharter' in value) || value['invalidatedCharter'] === undefined) return false;
    return true;
}

export function FreelancerStatisticsFromJSON(json: any): FreelancerStatistics {
    return FreelancerStatisticsFromJSONTyped(json, false);
}

export function FreelancerStatisticsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FreelancerStatistics {
    if (json == null) {
        return json;
    }
    return {
        
        'rating': json['rating'],
        'missionsCount': json['missionsCount'],
        'recommendationsCount': json['recommendationsCount'],
        'appraisalsWithRatesCount': json['appraisalsWithRatesCount'],
        'invalidatedCharter': json['invalidatedCharter'],
    };
}

export function FreelancerStatisticsToJSON(value?: FreelancerStatistics | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'rating': value['rating'],
        'missionsCount': value['missionsCount'],
        'recommendationsCount': value['recommendationsCount'],
        'appraisalsWithRatesCount': value['appraisalsWithRatesCount'],
        'invalidatedCharter': value['invalidatedCharter'],
    };
}

